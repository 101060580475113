<template>
	<div>
		<header class="page-layout__header--sticky">
			<BuilderHeaderSiteSettings class="page-layout__header" />
			<ZyroRibbon
				v-if="showUpgradeRibbon"
				v-qa="'sitesettings-ribbon'"
				:button-text="$t('siteSettings.ribbon.upgradeButton')"
				@click.native="handleUpgradeClick"
			>
				{{ $t('siteSettings.ribbon.upgradeText') }}
			</ZyroRibbon>
		</header>
		<BuilderSiteSettings
			:is-ribbon-visible="showUpgradeRibbon"
			class="page-layout page-layout--no-padding-left"
			:active-section="$route.name"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
} from 'vuex';

import BuilderHeaderSiteSettings from '@/components/builder-view/headers/BuilderHeaderSiteSettings.vue';
import BuilderSiteSettings from '@/components/site-settings/BuilderSiteSettings.vue';
import ZyroRibbon from '@/components/ui/ZyroRibbon.vue';
import { SUBSCRIPTION_SETTINGS_ROUTE } from '@/router';

export default {
	components: {
		BuilderSiteSettings,
		ZyroRibbon,
		BuilderHeaderSiteSettings,
	},
	setup() {
		return { SUBSCRIPTION_SETTINGS_ROUTE };
	},
	computed: {
		...mapState(['hasActivePlan']),
		...mapState('user', ['isUserDesigner']),
		showUpgradeRibbon() {
			return !this.hasActivePlan
				&& !this.isUserDesigner
				&& this.$route.name !== SUBSCRIPTION_SETTINGS_ROUTE;
		},
	},
	methods: {
		...mapActions('subscription', ['autoConnectPlan']),
		async handleUpgradeClick() {
			await this.autoConnectPlan();
			if (!this.hasActivePlan) {
				this.$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import './PageLayout';
</style>
