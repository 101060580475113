<template>
	<div class="builder-header">
		<ZyroLogo
			:href="logoUrl"
			:title="user ? $t('common.mySites') : $t('common.templates')"
		/>
		<h3 class="builder-header__title z-body-small">
			{{ websiteName }}
		</h3>
		<div class="builder-header__item-right">
			<ZyroUserDropdown class="builder-header__user-dropdown show-mobile-up" />
			<ZyroButton
				theme="header"
				color="red"
				data-qa="sitesettings-btn-editwebsite"
				@click="$router.push({ name: BUILDER_ROUTE })"
			>
				{{ isUserDesigner ? $t('common.editTemplate') : $t('common.editWebsite') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import ZyroLogo from '@/components/ui/ZyroLogo.vue';
import ZyroUserDropdown from '@/components/ui/ZyroUserDropdown.vue';
import { BUILDER_ROUTE } from '@/router';
import { useRedirects } from '@/use/useRedirects';

export default {
	components: {
		ZyroLogo,
		ZyroUserDropdown,
	},
	setup() {
		const { logoUrl } = useRedirects();

		return {
			logoUrl,
			BUILDER_ROUTE,
		};
	},
	computed: {
		...mapState(['websiteName']),
		...mapState('user', [
			'user',
			'isUserDesigner',
		]),
	},
};
</script>

<style scoped lang="scss">
@import "./BuilderHeader";

.builder-header {
	&__item-right {
		flex-shrink: 0;
	}
}
</style>
