<template>
	<div>
		<nav
			class="navigation"
			:class="{ 'navigation--open' : isMenuOpen }"
			@click="$emit('close')"
		>
			<RouterLink
				v-for="item in items"
				:key="`${item}-section`"
				v-qa="`sitesettings-link-${item}`"
				:to="item"
				replace
				class="navigation__link z-body-small"
				:class="{ 'navigation__link--active': $route.name === item }"
			>
				{{ $t(`siteSettings.nav.${item}`) }}
			</RouterLink>
		</nav>
		<Transition name="fade">
			<div
				v-show="isMenuOpen"
				class="navigation__backdrop"
				@click="$emit('close')"
			/>
		</Transition>
	</div>
</template>

<script>

export default {
	props: {
		isMenuOpen: {
			type: Boolean,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.navigation {
	position: fixed;
	width: $settings-navigation-width;
	height: calc(100vh - #{$header-height});
	background-color: $light;

	@include tablet-view {
		z-index: z-index(settings--navigation-mobile);
		width: 100%;
		height: auto;
		transition: all 0.6s cubic-bezier(0.45, 0, 0.1, 1);
		transition-property: opacity, transform, background-color;
		transform: translateY(-100%);

		&--open {
			transform: translateY(0);
		}
	}

	&__backdrop {
		@include backdrop(z-index(settings--navigation-backdrop));
	}

	&__link {
		display: inline-block;
		width: 100%;
		padding: 20px 37px;
		text-decoration: none;
		transition: 0.2s ease-out;
		transition-property: color, background-color, border;

		@include tablet-view {
			padding: 20px 16px;
			border-bottom: 1px solid $grey-100;
		}

		&:hover,
		&:focus,
		&--active {
			color: $secondary;
			background: $secondary-lighter;
			outline: none;
		}

		&--active {
			border-right: 2px solid $secondary;

			@include tablet-view {
				border-right: none;
				border-bottom: 2px solid $secondary;
			}
		}
	}
}
</style>
