<template>
	<div
		v-if="user"
		class="user-dropdown"
		:class="{ open }"
		@mouseleave="open = false"
		@mouseenter="open = true"
	>
		<div
			data-qa="popupwindow-icon-userprofile"
			class="user-dropdown__bubble user-dropdown__bubble--small"
			@touchstart="open = !open"
		>
			{{ firstNameLetter }}
		</div>
		<div class="user-dropdown__content">
			<div class="user-info">
				<div class="user-dropdown__bubble">
					{{ firstNameLetter }}
				</div>
				<div class="user-info__data">
					<div class="user-info__data-name">
						{{ user.fullName }}
					</div>
					<div class="user-info__data-email z-body-small">
						{{ user.email }}
					</div>
					<a
						:href="dashboardLink"
						class="user-info__data-sites-link z-button-small"
					>
						{{ $t('common.dashboard') }}
					</a>
				</div>
			</div>
			<!-- This is commented out for now, as the MVP of the component
			is without the functionality of the links. This is a TODO: once the designs are ready.

			<div class="spacer" />
			<div class="user-dropdown__content-links">
				<a
					data-qa="popupwindow-link-accountsettings"
					class="user-dropdown__content-link z-body-small"
					:href="`${homepage}/sites`"
				>
					Account Settings
				</a>
				<a
					data-qa="popupwindow-link-password"
					class="user-dropdown__content-link z-body-small"
					:href="`${homepage}/sites`"
				>
					Password
				</a>
				<a
					data-qa="popupwindow-link-logout"
					class="user-dropdown__content-link z-body-small"
					@click="logout"
				>
					Sign Out
				</a>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { WWW_REDIRECT_PATHS } from '@/constants';
import { useRedirects } from '@/use/useRedirects';

const { SITES } = WWW_REDIRECT_PATHS;

export default {
	props: {
		logout: {
			type: Function,
			default: () => {},
		},
	},
	setup() {
		const { getRedirectToWWWUrl } = useRedirects();

		const dashboardLink = getRedirectToWWWUrl({ path: SITES });

		return { dashboardLink };
	},
	data() {
		return { open: false };
	},
	computed: {
		...mapState('user', ['user']),
		firstNameLetter() {
			return this.user.fullName[0].toUpperCase();
		},
	},
};
</script>

<style lang="scss" scoped>
.user-dropdown {
	display: inline-flex;

	&__bubble {
		z-index: 1;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
		font-size: 30px;
		font-weight: 300;
		color: $dark;
		text-transform: uppercase;
		cursor: pointer;
		background-color: $grey-100;
		border-radius: 50%;

		&--small {
			width: 30px;
			height: 30px;
			font-size: unset;
		}
	}

	&__content {
		position: absolute;
		top: $header-height;
		right: -63px;
		padding: 26px 28px;
		pointer-events: none;
		visibility: hidden;
		background-color: $light;
		opacity: 0;
		transition: 0.2s $easing-accelerate;
		transform: translateY(20px);

		&::before {
			position: absolute;
			right: 0;
			bottom: 100%;
			z-index: -1;
			width: 100%;
			height: 60px;
			content: "";
		}

		&::after {
			position: absolute;
			top: -5px;
			right: 73px;
			width: 10px;
			height: 10px;
			content: "";
			background-color: $light;
			box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
			transform: rotate(45deg);
		}

		.open & {
			pointer-events: all;
			visibility: visible;
			box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
			opacity: 1;
			transition: 0.2s $easing-decelerate;
			transform: translateY(0);
		}

		.sticky & {
			top: 100%;
		}

		&-links {
			display: flex;
			flex-direction: column;
		}

		&-link {
			margin-bottom: 8px;
			color: $dark;
			text-align: left;
			text-decoration: none;
			cursor: pointer;
			background-color: $light;
			border: none;

			&:active {
				color: $secondary;
			}

			&[disabled] {
				color: $grey-600;
				pointer-events: none;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.user-info {
	display: flex;
	align-items: center;
	min-width: 275px;

	&__data {
		margin-left: 20px;

		&-name {
			font-weight: 500;
			color: $dark;
			word-break: break-all;
		}

		&-email {
			color: $grey-600;
			word-break: break-all;
		}

		&-sites-link {
			display: inline-block;
			margin-top: 16px;
			color: $primary;
			text-decoration: none;
		}
	}
}

.spacer {
	width: 100%;
	height: 2px;
	margin: 16px 0;
	background-color: $grey-200;
}

@include mobile-view {
	.user-dropdown {
		position: relative;
		max-width: 410px;

		&__content {
			min-width: 330px;
			transform: translateY(-20px);

			&::before {
				position: absolute;
				right: 0;
				bottom: 100%;
				z-index: -1;
				width: 100%;
				height: 40px;
				content: "";
			}

			&::after {
				position: absolute;
				top: -5px;
				right: 15px;
				width: 10px;
				height: 10px;
				content: "";
				background-color: $light;
				box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
				transform: rotate(45deg);
			}

			.sticky & {
				top: calc(100% + 20px);
			}
		}
	}
}
</style>
