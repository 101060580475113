<template>
	<div>
		<div
			:class="{ 'settings__layout--fixed' : isMenuOpen }"
		>
			<Navigation
				:is-menu-open="isMenuOpen"
				:items="nav"
				@close="closeNav"
			/>
			<main
				class="settings__page"
			>
				<div
					class="settings__page-inner"
					:class="{ 'settings__page-inner--full-width': activeSection === 'forms' }"
				>
					<ZyroHamburger
						class="settings__burger show-up-to-settings-tablet"
						:is-open="isMenuOpen"
						color="var(--dark)"
						:title="$t('common.menu')"
						@click="toggleMenu"
					/>
					<Transition
						name="slide-left"
						mode="out-in"
					>
						<RouterView :is-ribbon-visible="isRibbonVisible" />
					</Transition>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import Navigation from '@/components/site-settings/components/Navigation.vue';
import ZyroHamburger from '@/components/ui/ZyroHamburger.vue';
import { screenBreakpoints } from '@/enums/screenBreakpoints';

export default {
	screenBreakpoints,
	components: {
		ZyroHamburger,
		Navigation,
	},
	props: {
		activeSection: {
			type: String,
			default: 'general',
		},
		isRibbonVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { isMenuOpen: false };
	},
	computed: {
		...mapState('user', ['isUserDesigner']),
		nav() {
			return !this.isUserDesigner ? Object.keys(this.$t('siteSettings.nav')) : Object.keys(this.$t('siteSettings.curatedNav'));
		},
	},
	methods: {
		toggleMenu() {
			this.isMenuOpen = !this.isMenuOpen;
		},
		closeNav() {
			if (window.innerWidth <= screenBreakpoints.TABLET && this.nav.includes(this.$route.name)) {
				this.toggleMenu();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
$side-margin: 78px;

.settings {
	&__layout--fixed {
		position: fixed;
		width: 100%;
	}

	&__page {
		position: relative;
		min-height: calc(100vh - calc(#{$header-height}));
		padding: 34px 16px;
		margin-left: $settings-navigation-width;
		background: $grey-100;

		@include tablet-view {
			margin-left: 0;
		}

		@include mobile-view {
			padding: 24px 16px;
		}
	}

	&__page-inner {
		position: relative;
		max-width: 966px;
		margin: 0 auto;

		&--full-width {
			max-width: 97%;
		}
	}

	&__burger {
		position: absolute;
		right: 0;
		margin-top: 6px;
	}
}
</style>
